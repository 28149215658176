<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
						<el-form-item label="驿站名称" prop="name">
							<el-input v-model="ruleForm.name" style="width: 6rem;"></el-input>
						</el-form-item>
						<el-form-item label="驿站简介" prop="jianjie">
							<el-input type="textarea" :autosize="{ minRows: 7, maxRows: 7}" style="width: 6rem;"
								v-model="ruleForm.jianjie"></el-input>
						</el-form-item>
						<el-form-item label="缩略图" required>
							<el-upload :action="baseurl + '/api/login/upload'" list-type="picture-card" ref="myimg"
								:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="fileList">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</el-form-item>
						<el-form-item label="负责人" prop="fzren">
							<el-input v-model="ruleForm.fzren" style="width: 3rem;"></el-input>
						</el-form-item>
						<el-form-item label="联系电话" prop="lxphone">
							<el-input v-model="ruleForm.lxphone" style="width: 3rem;"></el-input>
						</el-form-item>
						<el-form-item label="账号" prop="username">
							<el-input v-model="ruleForm.username" style="width: 3rem;"></el-input>
						</el-form-item>
						<el-form-item label="所属地区" prop="area_id">
							<el-select v-model="ruleForm.area_id" placeholder="请选择" style="width: 3rem;">
								<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="详细地址" prop="address">
							<el-input v-model="ruleForm.address" style="width: 6rem;"></el-input>
						</el-form-item>
						<el-form-item label="地图坐标" prop="lng_lat" style="width: 520px;">
							<el-input v-model="ruleForm.lng_lat" placeholder="输入经纬度,示例:116.37,40.31">
								<template slot="append">
									<el-button type="primary" @click="openmap">打开地图</el-button>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="排序" prop="weigh">
							<el-input v-model="ruleForm.weigh" type="number" style="width: 6rem;"></el-input>
						</el-form-item>
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="backpage">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
	export default {
		data() {
			return {
				baseurl: "",
				ruleForm: {
					name: "",
					jianjie: "",
					image: "",
					area_id: '',
					fzren: "",
					lxphone: "",
					username:'',
					address: "",
					lng_lat: "",
					weigh: 1,
				},
				rules: {
					name: [{
						required: true,
						message: '请输入驿站名称',
						trigger: 'blur'
					}],
					jianjie: [{
						required: true,
						message: '请输入驿站简介',
						trigger: 'blur'
					}],
					image: [{
						required: true,
						message: '请选择上传缩略图',
						trigger: 'blur'
					}],
					area_id: [{
						required: true,
						message: '请选择所属区县',
						trigger: 'change'
					}],
					fzren: [{
						required: true,
						message: '请输入负责人',
						trigger: 'blur'
					}],
					lxphone: [{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					}],
					username:[{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					lng_lat: [{
						required: true,
						message: '请输入经纬度',
						trigger: 'blur'
					}]
				},
				dialogImageUrl: '',
				dialogVisible: false,
				fileList:[],
				options: []
			}
		},
		created() {
			this.baseurl = this.$URL

			this.$post({
				url: '/api/area/index',
				params: {
					pid: 2
				}
			}).then((res) => {
				this.options = res
			})
		},
		methods: {
			submitForm() {
				if(this.$refs.myimg.uploadFiles.length<=0){
					this.$message.error('请上传缩略图')
					return
				}
				let filelist = this.$refs.myimg.uploadFiles.map((item)=>{ 
					let obj = {
						id:item.id?item.id:item.response.id,
						name:item.name?item.name:item.response.file_name,
						url:item.url?item.url:item.response.fullurl,
					}
					return obj
				})
				this.ruleForm.image = filelist.map((item)=>{ return item.id}).join(',')
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						console.log(this.ruleForm)
						this.$post({
							url: '/api/stage/add',
							params: this.ruleForm
						}).then((res) => {
							this.$message.success('添加成功')
							this.$router.go(-1)
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			openmap() {
				window.open('https://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleAvatarSuccess(res, file) {
				this.ruleForm.image = res.id
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			backpage() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>